import { ContentWrapper, PageHeader, useTableSort, useToastAction } from '@metaforcelabs/metaforce-core';
import {
  deleteFile,
  getAllDocumentsApi,
  getCompanyMetaDataColumnsApi,
  getFileApi,
  rollbackFileApi
} from '../../../api/archive';
import {
  generatePdf
} from '../../../api/smartforms';
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import Filter from './components/Filter';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { Button, MenuContextList } from '../../../components';
import DateTimeParser from '../../../utils/DateTimeParser';
import Modal from '../../../components/Modal';
import { ArchiveIcon, DocumentDownloadIcon, ReplyIcon } from '@heroicons/react/outline';
import Badge from '../../../components/Badge';
import { archivedSmartformDocumentType, documentTypes } from '../../../utils/constants';
import { config } from '../../../utils/config';
import { OidcRoutesContext } from '../../../contexts';
import { HighlightCard, HighlightCardVariants } from '@metaforcelabs/metaforce-core';

const DocumentType = {
  [documentTypes.default]: {
    text: 'Default Document',
    badgeType: 'info'
  },
  [documentTypes.smartForms]: {
    text: 'Smartform',
    badgeType: 'warn'
  },
  [documentTypes.digitallySignedFile]: {
    text: 'Digitally Signed File',
    badgeType: 'success'
  }
};

const ArchiveList = () => {
  const initAction = useToastAction();
  const getAllDocumentsAction = useToastAction();
  const downloadFileAction = useToastAction();
  const deleteFileAction = useToastAction();
  const rollbackFileAction = useToastAction();

  const [documents, setDocuments] = useState([]);
  const [metadataColumns, setMetadataColumns] = useState({
    colunms: [],
    folders: []
  });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedVersionDocument, setSelectedVersionDocument] = useState(null);
  const [requestData, setRequestData] = useState({
    createdFrom: DateTimeParser.toLocalJSONFormat(
      DateTimeParser.setSOD(DateTimeParser.subtract(new Date(), 0, 1, 0))
    ),
    createdTo: DateTimeParser.toLocalJSONFormat(DateTimeParser.setEOD(new Date())),
    foldersIds: [],
    searchQuery: ''
  });

  const { customerInfo } = useContext(OidcRoutesContext) || {};

  const handleOnFilter = (values, setSubmitting) => {
    getAllDocumentsAction.execute(async () => {
      const getAllDocumentsResult = await getAllDocumentsApi(values);
      setRequestData(values);
      setDocuments(getAllDocumentsResult);

      if (setSubmitting) {
        setSubmitting(false);
      }
    }, 'Error');
  };

  const handleDownload = (document) => {
    downloadFileAction.execute(async () => {
      if (document.documentVersions && document.documentVersions.length > 0) {
        const highestVersionValue = Math.max(...document.documentVersions.map((o) => o.version), 0);
        const documentHighestVersion = document.documentVersions.find(
          (d) => d.version === highestVersionValue
        );

        await getFileApi(documentHighestVersion.id);
      } else {
        await getFileApi(document.id);
      }
    }, 'Error');
  };

  const handleDelete = (document, requestData) => {
    deleteFileAction.execute(async () => {
        await deleteFile(document.id);
        const getAllDocumentsResult = await getAllDocumentsApi(requestData);
        setDocuments(getAllDocumentsResult);
    }, 'There was an error during delete operation');
  };

  const handleDowloadPdf = async (document) => {
    await generatePdf(document.smartformId, document.dialogId, 'smartform.pdf');
  }

  const init = () => {
    initAction.execute(async () => {
      const [getCompanyMetaDataColumnsResult, getAllDocumentsResult] = await Promise.all([
        getCompanyMetaDataColumnsApi(requestData),
        getAllDocumentsApi(requestData)
      ]);
      setMetadataColumns(getCompanyMetaDataColumnsResult);
      setDocuments(getAllDocumentsResult);

      console.log('getCompanyMetaDataColumnsResult', getCompanyMetaDataColumnsResult);
    }, 'Error');
  };

  useEffect(() => {
    console.log('documents useEffect', documents);
  }, [documents]);

  const handleRollback = useCallback((currentVersion, selectedDocument) => {
    setShowConfirmationModal(true);
    setSelectedVersionDocument(currentVersion);
  }, []);

  const handleVersion = useCallback((document) => {
    setSelectedDocument({
      ...document,
      documentVersions: [
        {
          id: document.id,
          createdDate: document.createdDate,
          updatedDate: document.updatedDate,
          createdBy: document.createdBy,
          updatedBy: document.updatedBy,
          companyId: document.companyId,
          fileName: document.fileName,
          storedBlobId: document.storedBlobId,
          version: document.version
        },
        ...document.documentVersions
      ]
    });
    setShowModal(true);
  }, []);

  const menuContextListActions = useCallback((document, requestData) => {
    let options = [
      {
        name: 'Download',
        onClick: () => {
          handleDownload(document);
        }
      }
    ];

    if (document.type === documentTypes.smartForms) {
      const url = `${config.smartFormsUiBaseUrl}/archive/${customerInfo.customerId}/${document.id}`;
      options.push({
        name: 'Download PDF',
        onClick: () => {
          handleDowloadPdf(document);
        }
      });
      options.push({
        name: 'Go to Smartform',
        href: url,
        newWindow: true
      });
    }

    options.push({
        name: 'Delete',
        onClick: () => {
          handleDelete(document, requestData);
        }
      })

    if (document.type === documentTypes.digitallySignedFile && hasSmartformOrigin(document)) {
      const url = `${config.smartFormsUiBaseUrl}/archive/${customerInfo.customerId}/${document.search6}`;
      options.push({
        name: 'View Smartform origin',
        href: url,
        newWindow: true
      });
    }

    if (document.documentVersions.length) {
      options.push({
        name: 'Version history',
        onClick: () => {
          handleVersion(document);
        }
      });
    }

    return options;
  }, []);

  const hasSmartformOrigin = (document) => {
    return !!document.search6 && 
            document.search5 === archivedSmartformDocumentType.signedPdfSmartform;
  }

  const handleConfirm = useCallback(() => {
    rollbackFileAction.execute(async () => {
      await rollbackFileApi({
        sourceFileVersionId: selectedDocument.id,
        targetFileId: selectedVersionDocument.id
      });

      const getAllDocumentsResult = await getAllDocumentsApi(requestData);

      setDocuments(getAllDocumentsResult);
      setShowConfirmationModal(false);
      setShowModal(false);
    }, 'Error');
  }, [selectedDocument, selectedVersionDocument]);

  const handleCancelConfirm = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  useEffect(() => {
    init();
  }, []);

  const orderBy = async (propertyName, sortDirection) => {
    propertyName = propertyName.charAt(0).toLowerCase() + propertyName.slice(1);
    const orderedDocuments = [...documents].sort((a, b) => {
      if (a[propertyName] < b[propertyName]) {
        return sortDirection === 1 ? -1 : 1;
      }
      if (a[propertyName] > b[propertyName]) {
        return sortDirection === 1 ? 1 : -1;
      }
      return 0;
    });
    setDocuments(orderedDocuments);
  }

  return (
    <ContentWrapper>
      <PageHeader
        title="Archive"
      />

      <div>
        <HighlightCard
          body={"Search your archives for documents that has been created by your organization. Your Access right will control which folders you can search in."}
          header={"Search in Archive"}
          hideButton={true}
          variant={HighlightCardVariants.yellow}
          rightContent={
            <div className='flex justify-end'>
              <div className='bg-document-yellow-dark flex px-8 py-4 items-center justify-center rounded-full'>
                <div className='text-document-yellow-dark bg-black h-20 w-20 rounded-full relative flex items-center justify-center'>
                  <div className=' h-10 w-full top-0 right-0 left-0 bottom-0 text-document-yellow-dark flex items-center justify-center'>
                    <ArchiveIcon icon={ArchiveIcon} className='w-14 h-14 p-1' />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>

      <Filter metadataColumns={metadataColumns} onSubmit={handleOnFilter} />

      <Table id="table">
        <TableHead>
          {metadataColumns.colunms
            .sort((a, b) => a.order - b.order)
            .map(
              (metadataColumn) =>
                metadataColumn.displayInColumn && (
                    <TableHeader key={metadataColumn.name} data-cy={`${metadataColumn.name}Header`} sortBy={metadataColumn.isSortable ? metadataColumn.name : null} orderBy={orderBy}>
                      {metadataColumn.presentationLabel || metadataColumn.name}
                    </TableHeader>
                )
            )}
          <TableHeader additionalClass=" z-20 right-0 bg-gradient-to-r from-transparent to-gray-50"></TableHeader>
        </TableHead>

        <TableBody data-cy="listTableBody">
          {documents &&
            documents.map((document, index) => (
                <TableRow key={document.id}>
                  {metadataColumns.colunms
                    .sort((a, b) => a.order - b.order)
                    .map((metadataColumn) => {
                      const name = metadataColumn.name;
                      const valueKey = name.charAt(0).toLowerCase() + name.slice(1);

                      return (
                        metadataColumn.displayInColumn && (
                            <TableColumn key={name} data-cy={`${name}Cell`}>
                              {valueKey === 'type' ? (
                                <Badge
                                  type={DocumentType[document[valueKey]] ? DocumentType[document[valueKey]].badgeType : DocumentType["0"].badgeType}
                                  text={DocumentType[document[valueKey]] ? DocumentType[document[valueKey]].text : DocumentType["0"].text}
                                />
                              ) : valueKey === 'createdDate' ||
                                valueKey === 'updatedDate' ||
                                DateTimeParser.isValidFormat(document[valueKey]) ? (
                                DateTimeParser.toLocaleDateTimeString(document[valueKey])
                              ) : (
                                document[valueKey]
                              )}
                            </TableColumn>
                        )
                      );
                    })}
                  <TableColumn className="whitespace-nowrap text-sm text-gray-500 sticky right-0 pr-3 md:pr-0">
                    <MenuContextList
                      dataCy="listActionsBtn"
                      container="body"
                      actions={menuContextListActions(document, requestData)}
                      menuUpVariant={index > 1 && index >= documents.length - 2}
                    />
                  </TableColumn>
                </TableRow>
            ))}
        </TableBody>
      </Table>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        size="medium"
        title="Version history"
      >
        <div className="mt-4">
          <div data-cy="listOfDocumentVersions">
            {selectedDocument?.documentVersions
              .sort((a, b) => a.version - b.version)
              .map((document, index) => (
                <div className="flex" key={index}>
                  <div className="flex flex-col items-center mr-4">
                    <div>
                      <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                        {index + 1}
                      </div>
                    </div>
                    {index + 1 < selectedDocument.documentVersions.length && (
                      <div className="w-px h-full bg-gray-300"></div>
                    )}
                  </div>
                  <div className="pb-8 ">
                    <h3
                      data-cy="modalFileName"
                      className="mb-2 text-lg font-semibold text-gray-900 dark:text-white"
                    >
                      {document.fileName}
                    </h3>

                    <div className="flex mb-5">
                      <p className="mr-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        Updated by: {document.updatedBy}
                      </p>

                      <time className="text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                        at {DateTimeParser.toLocaleDateTimeString(document.updatedDate)}
                      </time>
                    </div>

                    <div>
                      <Button
                        variant={Button.variants.secondary}
                        disabled={rollbackFileAction.isExecuting}
                        onClick={() => handleDownload(document)}
                      >
                        Download
                        <DocumentDownloadIcon className="ml-2 w-5 h-5" />
                      </Button>

                      {index + 1 < selectedDocument.documentVersions.length && (
                        <Button
                          variant={Button.variants.secondary}
                          className="ml-4"
                          disabled={rollbackFileAction.isExecuting}
                          onClick={() => handleRollback(document, selectedDocument)}
                        >
                          Rollback
                          <ReplyIcon className="ml-2 w-5 h-5" />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <button
            data-cy="modalCloseBtn"
            type="button"
            className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        size="medium"
        title="Confirm rollback"
      >
        <div className="mt-4">
          <p>All versions of the document newer than the selected one will be deleted.</p>
          <div className="mt-4">
            <Button variant={Button.variants.primary} onClick={handleConfirm}>
              Confirm
            </Button>

            <Button
              variant={Button.variants.secondary}
              className="ml-4"
              onClick={handleCancelConfirm}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </ContentWrapper>
  );
};

export default memo(ArchiveList);
