import React, { Fragment } from 'react';
import { Menu, Disclosure, Transition } from '@headlessui/react';
import { useReactOidc } from '@axa-fr/react-oidc-context';

import { UserIcon } from '@heroicons/react/outline';
import { config } from '../../utils/config';

import { ViewGridIcon } from '@heroicons/react/outline';
import { useClassNames } from '@metaforcelabs/metaforce-core';

const MissingLicense = () => {
  const { logout } = useReactOidc();
  const classNames = useClassNames();

  const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#', onClick: logout }
  ];

  return (
    <div className="relative min-h-screen flex flex-col bg-white">
      <Disclosure as="nav" className="bg-white border-b border-gray-200">
        {() => (
          <>
            <div className=" mx-auto px-5">
              <div className="flex justify-between h-16">
                <div className="flex-shrink-0 flex items-center w-64">
                  <a href={config.centerpointUiBaseUrl} rel="noreferrer" target="_blank" className={'mr-3 mt-3'}>
                    <ViewGridIcon className={'h-7 w-7'} />
                  </a>
                  <img
                    className="h-8 w-auto"
                    src="https://cdn2.hubspot.net/hubfs/4814774/Metaforce%20logo%20web.png"
                    alt="Metaforce"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center text-sm">
                  {/* <div className="float-right mr-5">
                      <div className="font-semibold">{ profileOverview.name }</div>
                      <div>{ profileOverview.orgNumber }</div>
                  </div> */}
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                            className={`p-1 rounded-full text-black hover:text-white focus:outline-none hover:bg-red-400 active:bg-red-400 nav-button align-middle ${
                              open ? 'active' : ''
                            }`}
                          >
                            <span className="sr-only">Open user menu</span>
                            <UserIcon className="h-6 w-6" aria-hidden="true" />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    onClick={item.onClick}
                                    href={item.href}
                                    className={classNames.classNames(
                                      active ? 'bg-red-200' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <div className="pt-2 pb-10 flex bg-gray-50 h-screen">
        <div className={'flex  w-full mx-auto '}>
          <p className="text-2xl mt-6 ml-6">Missing license to access Archive - Admin</p>
        </div>
      </div>
    </div>
  );
};
export default MissingLicense;
